import React from "react";

import SubscribeForm from "../Subscribe/Subscribe";
import './footer.css';

export default function Footer() {
    return (
        <div className="footer">
            <span className="footer-header">
                <li className="closed">MONDAY
                    <p>CLOSED</p>
                </li>
                <li className="open">TUESDAY
                    <p>9AM-5PM</p>
                </li>
                <li className="open">WEDNESDAY
                    <p>9AM-5PM</p>
                </li>
                <li className="open">THURSDAY
                    <p>1PM-5PM</p>
                </li>
                <li className="closed">FRIDAY
                    <p>CLOSED</p>
                </li>
                <li className="open">SATURDAY
                    <p>9AM-5PM</p>
                </li>
                <li className="closed">SUNDAY
                    <p>CLOSED</p>
                </li>
            </span>

            <span className="footer-body">
                <div className="footer-logo">
                    <div className="image-footer"> </div>
                    <p className="address">19111 DETROIT ROAD, Suite 105<br></br>ROCKY RIVER, OHIO 44146</p>
                    <div className="footer-links">
                        <span className="sm-imgs"><a href="https://www.facebook.com/ginassalonandwigs/"><img alt="facebook" src={require('../images/social.png')} /></a>
                <a href="https://www.instagram.com/ginas.salon/"><img alt="instagram" src={require('../images/insta-white.png')} /></a>
                <a href="https://www.facebook.com/ginassalonandwigs/videos"><img alt="youtube" src={require('../images/yt-icon2.png')} /></a></span>
                        216.226.6788</div>
                </div>
                <div className="footer-subscribe"><SubscribeForm /></div>
            
            </span>

            
        </div>
    )
}