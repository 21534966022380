import React from "react";
import "../styles/home.css";
import "../styles/about.css"


export default function About() {

  return (
    <div className="homepage">
      <div className="subpage-header">
        <div className="image-spot2">
        </div>
      </div>
        <p className="tagline">YOUR HAIR IS YOUR IDENTITY. WEAR IT WITH CONFIDENCE</p>
      <div className="about-home">
        <p id="aboutp">
            
              <img alt="GINA" id="headshot" src={require('../images/Headshot.jpg')} />
            
            Gina’s Hair Salon and Wigs is recognized for providing a full range of services to both men and women throughout the Northeast Ohio area. We are well known for having the appropriate hair toppers or wigs for anyone who may be experiencing thinning hair or hair loss due to an autoimmune disease like Alopecia Areata, Crohn’s Disease, Grave’s Disease, Hashimoto’s Disease, Lupus, Rheumatoid Arthritis - as well as Trichotillomania (a hair-pulling disorder) and Chemotherapy.
            <br></br><br></br>
            As a licensed specialist for wigs, the professionals at Gina’s provide clients with a FREE 15-minute consultation. Not only do we assist our clients in how to properly care for their hair toppers and wigs, we teach them how to style their hair toppers and wigs in order to provide the confidence they are lacking. We know your hair system is an investment. Not only do we have the proper training, we have an open door policy to call us anytime you need help.
        </p>
        <p id="about-list">
            <li>EXPERT ADVICE</li>
            <br></br>
            <li>PROFESSIONAL STYLING</li>
            <br></br>
            <li>CUSTOM REPAIR</li>
            <br></br>
            <li>GUARANTEED RESULTS</li>
        </p>
        
          <div className="img-about">
            <img className="img" alt="woman before/after" src={require('../images/image4.jpg')} />
            <img  className="img" alt="woman before/after" id="img-mid" src={require('../images/image5.jpg')} />
            <img className="img" alt="woman before/after" src={require('../images/image6.jpg')} />
          </div>
      </div>
      
    </div>
  );
}