import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Navbar from "./components/Navbar/Navbar";
import Home from "./components/pages/Home";
import Services from "./components/pages/Services";
import About from "./components/pages/About";
import Footer from "./components/Footer/Footer";

function App() {
    return (
        
            <Router>
              <Navbar />
                <Routes>
                    <Route path={"/"} element={<Home />} />
                    <Route path={"/services"} element={<Services />} />
                    <Route path={"/about"} element={<About />} />
                    <Route path={"*"} element={<h1>No Content Found</h1>} />
                </Routes>
                <Footer />
            </Router>
    );
}

export default App;
