import React, { useState } from 'react';
import "./sub.css"

const SubscribeForm = () => {
  const [email, setEmail] = useState('');

  const handleInputChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you can implement your logic to subscribe the user
    console.log('Subscribed:', email);
    setEmail('');
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="email"
        value={email}
        onChange={handleInputChange}
        placeholder="EMAIL"
       
      />
      <button type="submit"><a href="mailto:ginassalonandwigs@gmail.com">SUBSCRIBE</a></button>
    </form>
  );
};

export default SubscribeForm;
