import React from "react";
import "../styles/home.css";

export default function Home() {

  return (
    <div className="homepage">
      <div className="rompala-home-logo">
        <div className="image-spot">
          
        </div>
        <video autoPlay muted loop playsInline  >
          <source src={require('../images/header.mp4')} type="video/mp4" />
        </video>
        <p className="tagline1">YOUR HAIR IS YOUR IDENTITY. WEAR IT WITH CONFIDENCE</p>
      </div>
     
      <div className="about-home">
        <h2 className="grabber">CLEVELAND'S MOST TRUSTED HAIR LOSS SOLUTION <br></br>FOR MEN & WOMEN</h2>
        <div className="container">
          
          <div className="img-container">
            <img  alt="top view male customer" id="img2" src={require('../images/image2.jpg')} />
          </div>

          <div className="txt-container">
            <li id="li1">WE PROVIDE SOLUTIONS FOR:</li>
                <li><span>ALOPECIA</span></li>
                <li><span>TRICHTOTILLOMANIA</span></li>
                <li><span>CHEMOTHERPY</span></li>
                <li><span>THINNING HAIR</span></li>
                <li><span>WIG DAMAGE</span></li>
              
          </div>
          
        </div><p id="guarantee">LICENSED WIG SPECIALIST 100% SERVICE GUARANTEED</p>
      </div>
        
      <div className="learn-container">
        <h3><a href="mailto:ginassalonandwigs@gmail.com?subject=Join Mailing List / Subscribe">CLICK HERE FOR FREE 15 MINUTE CONSULTATION</a></h3>
      </div>
    </div>
  );
}