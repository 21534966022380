import React, { useLayoutEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Menu } from "semantic-ui-react";
import "./nav.css";

export default function Navbar() {
  const [activeItem, setActiveItem] = useState("");

  const handleItemClick = (e, { name }) => setActiveItem(name);

  useLayoutEffect(() => {
    window.scrollTo(0,0)
  });

  return (
    <>
      
      <Menu className="nav" fluid widths={1}><img alt="logo" src={require('../images/logowhite.png')} />
        <Menu.Item
          name="HOME"
          active={activeItem === "Home"}
          onClick={handleItemClick}
          as={Link}
          to="/"
          id="home"
        />
        <Menu.Item
          name="SERVICES"
          active={activeItem === "services"}
          onClick={handleItemClick}
          as={Link}
          to="/services"
          id="services"
        />
        <Menu.Item
          name="ABOUT"
          active={activeItem === "about"}
          onClick={handleItemClick}
          as={Link}
          to="/about"
          id="about"
        /> 
      </Menu>
      </>
    
  );
}