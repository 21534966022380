import React from "react";
import "../styles/home.css";
import "../styles/services.css";

export default function Services() {
  

  return (
    <div className="homepage">
      <div className="subpage-header">
        <div className="image-spot2">
        </div>
      </div>
        <p className="tagline">YOUR HAIR IS YOUR IDENTITY. WEAR IT WITH CONFIDENCE</p>
      <div className="about-home services">
        <h1>SERVICES</h1>
        <p>          <p>GINA'S HAIR SALON AND WIGS PROVIDES A FULL RANGE OF SERVICES TO BOTH MEN AND WOMEN THROUGHOUT THE NORTHEAST OHIO AREA. WE ARE WELL KNOWN FOR HAVING THE APPROPRIATE HAIR TOPPERS OR WIGS FOR ANYONE WHO MAY BE EXPERIENCING THINNING HAIR OR HAIR LOSS DUE TO AN AUTOIMMUNE DISEASE</p></p>
        <br></br><br></br>
        <div className="container">
          <div className="img-container">
            <img  alt="top view male customer" id="img2" src={require('../images/image1.jpg')} />
          </div>

          <div className="txt-container">
            <li id="li1">WE PROVIDE SOLUTIONS FOR PEOPLE WITH:</li>
                <li><span>ALOPECIA AREATA</span></li>
                <li><span>CROHN'S DISEASE</span></li>
                <li><span>GRAVE'S DISEASE</span></li>
                <li><span>HASHIMOTO'S DISEASE</span></li>
                <li><span>LUPUS</span></li>
                <li><span>RHEUMATOID ARTHRITIS</span></li>
                <li><span>TRICHTOTILLOMANIA </span></li>
                <li><span>CHEMOTHERAPY</span></li>
                <li><span>any other hair loss issue</span></li>
          </div>
          
        </div>
      
        <br></br><br></br>
        <p>AS A LICENSED SPECIALIST FOR WIGS, GINA PROVIDES CLIENTS WITH A <p id="free">FREE</p> 15 MINUTE CONSULTATION</p>
        <br></br><p>NOT ONLY DO WE ASSIST OUR CLIENTS IN HOW TO PROPERLY CARE FOR THEIR HAIR TOPPERS AND WIGS, WE TEACH THEM HOW TO STYLE THEIR TOPPERS AND WIGS IN ORDER TO PROVIDE THE CONFIDENCE THEY ARE LACKING</p>
        <br></br><p>WE KNOW YOUR HAIR SYSTEM IS AN INVESTMENT. NOT ONLY DO WE HAVE THE PROPER TRAINING, WE HAVE AN OPEN DOOR POLICY TO CALL US ANYTIME IF YOU NEED HELP</p>
      </div>
          <h4>WATCH VIDEOS TO LEARN MORE</h4>
      <span className="video-examples">
        
            <div className="video-card"> 
            <h3>HAIR LOSS SOLUTIONS</h3>
            <iframe  src="https://www.youtube.com/embed/Wj1BrJdtTH4?si=XwaTOxfB3vjYpP-z" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>                     
            </div>
            <div className="video-card">
              <h3>CUSTOMER SATISFACTION</h3>
            <iframe  src="https://www.youtube.com/embed/j34Jl1u5s3U?si=Dr68vv4Ilh5KzqJh" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div className="video-card">
              <h3>WIG / TOPPER REPAIR</h3>
            <iframe  src="https://www.youtube.com/embed/E8qBj0clNak?si=IDYX6IN631N0b_Ec" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
      </span>
         
 
    </div>
  );
}